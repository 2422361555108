import { DsModalHandler, useDsNavigationLoaderStore } from "@devsalsa/vue-core";

import AccountRoutes from "@/core/modules/account/router";
import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";

import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

import i18n from "@/i18n";
import CampaignBuilderRoutes from "@/modules/campaign/builder/router";
import CampaignInformationRoutes from "@/modules/campaign/information/router";
import MarketplaceRoutes from "@/modules/campaign/marketplace/router";
import RecurringMarketPlaceRoutes from "@/modules/campaign/recurring-marketplace/router";
import CampaignsRoutes from "@/modules/campaign/router";
import ChatRoutes from "@/modules/chat/router";
import ContentRoutes from "@/modules/content/router";
import ContractsRoutes from "@/modules/contracts/router";
import DashboardRoutes from "@/modules/dashboard/router";
import JobInformationRoutes from "@/modules/job/information/router";
import JobsRoutes from "@/modules/job/router";
import OnboardingRoutes from "@/modules/onboarding/router";
import ProductsRoutes from "@/modules/product/router";
import RecurringCampaignsRoutes from "@/modules/recurring-campaigns/router";
import SettingsRoutes from "@/modules/settings/router";
import AdCenterRoutes from "@/modules/tiktok-ad-center/router";
import BoostCreationRoutes from "@/modules/tiktok-boost/router";
import PageNotFoundRoutes from "@/shared/modules/page-not-found/router";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "/notifications",
        name: "Notifications",
        component: () =>
          import("@/modules/notification/views/Notifications.vue"),
        beforeEnter: OnboardingGuard.withCompleteOnboarding,
        meta: { title: "common.route.notifications.meta" },
      },
      {
        path: "creator/:creatorId(\\d+)",
        name: "CreatorPortfolio",
        component: () =>
          import("@/modules/portfolio/views/CreatorPortfolio.vue"),
        beforeEnter: OnboardingGuard.withCompleteOnboarding,
        meta: {
          title: "modules.portfolio.meta",
        },
      },
    ],
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AccountRoutes,
  PageNotFoundRoutes,
  SettingsRoutes,
  DashboardRoutes,
  OnboardingRoutes,
  CampaignBuilderRoutes,
  CampaignInformationRoutes,
  JobInformationRoutes,
  ChatRoutes,
  CampaignsRoutes,
  JobsRoutes,
  ProductsRoutes,
  ContentRoutes,
  MarketplaceRoutes,
  RecurringMarketPlaceRoutes,
  AdCenterRoutes,
  BoostCreationRoutes,
  RecurringCampaignsRoutes,
  ContractsRoutes
);

const preloader = window.document.getElementById("preloader") as HTMLElement;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    }
    if (to.name === from.name && to.hash !== "" && to.hash !== from.hash) {
      //Avoid to scroll if we are clicking on tabs.
      return;
    }
    return new Promise((resolve) => {
      //The transition lasts 200ms.
      setTimeout(() => {
        //Manual way to scroll top to our custom container (https://github.com/vuejs/vue-router/issues/1187)
        //It could also be done in the afterEach navigation guard.
        document.getElementById("layout-content")?.scroll({
          top: 0,
          behavior: "smooth",
        });

        resolve({
          top: 0,
          behavior: "smooth",
          //This should be the correct way to do it according to the documentation,
          //but it doesn't work when the scroll is on a custom container (#layout-content in our case).
          //el: "#layout-content",
        });
      }, 200);
    });
  },
});

router.onError((error: unknown) => {
  console.log("VueJS router error handler", error);
  ErrorHandler.handle(error);
});
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    console.log(window.location.host, "host");
    console.log(import.meta.env.VITE_APP_DOMAIN, "app domain on env");

    // Check if the current domain is the main domain
    if (window.location.host === import.meta.env.VITE_APP_DOMAIN) {
      // Define the subdomain redirection logic
      const subdomainURL = `https://us.${import.meta.env.VITE_APP_DOMAIN}${
        to.fullPath
      }`;

      // Perform the redirection
      window.location.replace(subdomainURL);
    } else {
      if (preloader.classList.contains("hidden")) {
        useDsNavigationLoaderStore().enable();
      }
      window.document.title = `${
        import.meta.env.VITE_APP_TITLE
      } - ${i18n.global.t(<string>to.meta.title)}`;
      to.meta.from = from.name;
      next(); // Allow the navigation to proceed normally
      DsModalHandler.closeAll(); // Closes all modals before route change.
    }
  }
);
router.afterEach(() => {
  //Hide the preloader if not hidden yet.
  preloader.classList.add("hidden");
  useDsNavigationLoaderStore().disable();
});

export default router;
